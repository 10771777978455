<template>
  <div class="home-wrapper">
    <div class="banner">
      <el-image :src="bannerUrl">
        <div slot="placeholder" class="image-slot">
          <i class="el-icon-loading"></i>图片正在加载
        </div>
        <div slot="error" class="image-slot">
          <i class="el-icon-picture-outline"></i>
        </div>
      </el-image>
    </div>
    <!-- 简介 -->
    <div class="introduce-info">
      <div class="text">
        <div class="animation-box hide" id="introduce">
          <p class="en-text">COMPANY PROFILE</p>
          <p class="zh-text">医路行简介</p>
          <div class="info-text">
            我们成立于2021年，是一家集招聘、医疗专业技术能力培训、上岗考核于一体的管理咨询有限公司， 致力于为医疗企业提供一站式的人才孵化及培养服务，帮助企业树立人才竞争优势，提升经营成效。
          </div>
          <div class="info-text">
            在新一轮产业变革加速推进、新业态新模式不断涌现的背景下，我们迎合大时代浪潮，始终铭记“高效、专业、创新”的价值观，提供一站式运营服务，通过多样化的人才服务，帮助组织更好地发现、发展和发挥人才，为企业及领导者、职场人搭建可持续的战略性思维，帮助企业在一定范围内获取人才竞争优势，提升组织效能，为医疗企业人才配置与业务发展提供一体化支撑。
          </div>
          <div class="info-text">
            在数字经济与产业互联浪潮下，我们帮助更广泛用户实现人岗深度撮合与人才数智化管理，赋能区域人才决策及人才治理数智化转型，发挥精准就业、人才引进、人才治理、产才协同价值。
          </div>
          <div class="data-box">
            <div class="data-item">
              <p class="number">5000+</p>
              <p class="data-text">全球医院覆盖</p>
            </div>
            <div class="data-item">
              <p class="number">5000+</p>
              <p class="data-text">客户服务经验</p>
            </div>
            <div class="data-item">
              <p class="number">7×24h</p>
              <p class="data-text">技术支持</p>
            </div>
          </div>
        </div>
      </div>
      <div class="middle-banner sub-banner">
        <el-image :src="introImg">
          <div slot="placeholder" class="image-slot">
            <i class="el-icon-loading"></i>图片正在加载
          </div>
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
        <img class="view-img" src="../../assets/images/view_details.png" />
      </div>
    </div>
    <!-- 招聘 -->
    <div class="recruit flex">
      <div class="recruit-banner sub-banner">
        <el-image :src="recuritImg">
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
      </div>
      <div class="recruit-text">
        <p class="en-text">RECRUITMENT SYSTEM</p>
        <p class="zh-text">招聘体系</p>
        <div class="title-box flex" style="justify-content: space-between;">
          <div class="title-item">
            <img class="title-bg" src="../../assets//images/channel.png" />
            <div class="flex">
              <div class="line"></div>
              <div class="title">多资源渠道</div>
            </div>
            <div class="recruit-list">
              <div class="recruit-item">
                <img src="../../assets//images/icon/campus_recruitment@2x.png" />
                <span>校园招聘</span>
              </div>
              <div class="recruit-item">
                <img src="../../assets//images/icon/social_recruitment@2x.png" />
                <span>社会招聘</span>
              </div>
              <div class="recruit-item">
                <img src="../../assets//images/icon/web@2x.png" />
                <span>网络渠道</span>
              </div>
              <div class="recruit-item">
                <img src="../../assets//images/icon/headhunter@2x.png" />
                <span>猎头管理</span>
              </div>
            </div>
          </div>
          <div class="title-item">
            <img class="title-bg" src="../../assets//images/professional_team.png" />
            <div class="flex">
              <div class="line"></div>
              <div class="title">高效专业团队</div>
            </div>
            <div class="recruit-list">
              <div class="recruit-item">
                <img src="../../assets//images/icon/gold_medal@2x.png" />
                <span>金牌面试团队</span>
              </div>
              <div class="recruit-item">
                <img src="../../assets//images/icon/professional_process@2x.png" />
                <span>专业流程方法</span>
              </div>
              <div class="recruit-item">
                <img src="../../assets//images/icon/performance_incentive@2x.png" />
                <span>绩效激励体制</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 培训 -->
    <div class="train flex">
      <div class="train-content">
        <p class="en-text">PROFESSIONAL TRAINING SYSTEM</p>
        <p class="zh-text">专业培训体系</p>
        <div class="train-info flex">
          <div class="train-list">
            <div :class="['train-item','flex',activeId == item.id ? 'train-item-active':'']"
              v-for="item in trainlist"
              :key="item.id"
              @click="changeTrain(item.id)">
              <div :class="['train-icon', activeId == item.id ? 'train-icon-active':'']">
                <img :src="require(`../../assets/images/icon/${item.icon}.png`)" />
              </div>
              <p>{{item.title}}</p>
              <i class="el-icon-right" :style="{color:activeId == item.id?'#fff':'#ccc'}"></i>
            </div>
          </div>
          <div class="train-text">
            <img class="left-mark" src="../../assets/images/left_marks.png" />
            <div v-if="activeId">
              <p v-for="item in trainlist[parseInt(activeId)-1].text" :key="item">
                {{item}}
              </p>
            </div>
            <img class="right-mark" src="../../assets/images/right_marks.png" />
          </div>
        </div>
      </div>
      <div class="train-banner sub-banner">
        <el-image :src="trainImg">
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
      </div>
    </div>
    <!-- 咨询发展体系 -->
    <div class="consultation flex">
      <div class="consultation-banner sub-banner">
        <el-image :src="developImg">
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
      </div>
      <div class="consultation-content">
        <p class="en-text">CONSULTING DEVELOPMENT SYSTEM</p>
        <p class="zh-text">咨询发展体系</p>
        <div>
          <img src="../../assets/images/img_consultation.png" />
        </div>
      </div>
    </div>
    <!-- 特色 -->
    <div class="characteristic">
      <div class="chara-title">
        <p class="en-text">OUR CHARACTERISTICS</p>
        <p class="zh-text">我们的特色</p>
        <div class="line"></div>
      </div>
      <div class="chara-content flex">
        <div class="chara-item">
          <img src="../../assets/images/icon/high_efficiency.png" />
          <p class="chara-item-title">高效专业团队</p>
          <p class="chara-item-text">经验丰富且专业的招聘团队以及完善的人力资</p>
        </div>
        <div class="chara-item">
          <img src="../../assets/images/icon/major_team.png" />
          <p class="chara-item-title">专业培训团队</p>
          <p class="chara-item-text">拥有行业内专业的讲师队伍，独立设计并搭建全面、专业、系统的培训体系及考核体系</p>
        </div>
        <div class="chara-item">
          <img src="../../assets/images/icon/consultation_team.png" />
          <p class="chara-item-title">咨询发展团队</p>
          <p class="chara-item-text">依托强大的专业知识储备，与实战紧密结合，不断进行团队创新与发展</p>
        </div>
        <div class="chara-item">
          <img src="../../assets/images/icon/operation_team.png" />
          <p class="chara-item-title">运营管理团队</p>
          <p class="chara-item-text">提供一站式运营服务，帮助组织更好地发现、发展和发挥人才，为企业及领导者、职场人搭建可持续的战略性思维</p>
        </div>
        <div class="chara-item">
          <img src="../../assets/images/icon/consultation_team.png" />
          <p class="chara-item-title">销售市场团队</p>
          <p class="chara-item-text">其创始人在医疗器械行业服务近20余年，在销售领域、团队管理、人才发展及医疗专业培训具有丰富的经验。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getBanner } from './request'

export default {
  name: 'home',
  data() {
    return {
      username: '',
      avatarUrl: '',
      menus: [], //菜单
      code: '',
      isShow: false,
      trainlist: [
        {
          id: '1', 
          icon: 'product_knowledge',
          title: '产品知识', 
          text:['产品的构成及相关理论知识','产品特点、优势及临床获益']
        },
        {
          id: '2', 
          icon: 'professional_skills',
          title: '专业技能', 
          text:['行业概览:过去、现状及未来发展趋势','各类疾病的发病机制及治疗方式','临床指南解读及病例分析技巧']
        },
        {
          id: '3', 
          icon: 'sales',
          title: '销售通识', 
          text:['客户分析及沟通技巧','时间管理及情绪管理','有效科室会:演讲及PPT制作技巧','客户异议处理技巧']
        },
        {
          id: '4', 
          icon: 'actual',
          title: '实战操作', 
          text:['产品及设备的安装和患者准备','相关产品及系统的操作技巧','面向不同疾病的手术跟台技能']
        },
      ],
      activeId: '1', //当前点击的培训体系
      bannerUrl: '', //banner图地址
      introImg: '', //医路行简介
      recuritImg: '', //招聘体系 
      trainImg: '', //培训体系 
      developImg: '', //发展体系
    }
  },
  mounted() {
    window.addEventListener('scroll',this.scrolling)
    this.fetchImgUrl()
  },
  methods: {
    scrolling() {
      //滚动事件
      const windowHeight = document.body.clientHeight //网页可见区域的高度
      let scrollTop = window.pageYOffset ||  //滚动条距离顶部的距离
        document.documentElement.scrollTop ||
        document.body.scrollTop
      const introDOM = document.getElementById('introduce') //公司简介
      if(scrollTop >= (introDOM.offsetHeight - windowHeight)){
        if(!introDOM.classList.contains('show')){
          introDOM.classList.add('show')
          introDOM.classList.remove('hide')
        }
      }
    },
    changeTrain(id){
      //点击培训体系
      this.activeId = id
    },
    async fetchImgUrl() {
      let res = await getBanner({
        type: 'index',
        provider: 'index'
      })
      if(res.data.data){
        const imgUrl = JSON.parse(res.data.data)
        this.bannerUrl = imgUrl.homeBanner
        this.introImg = imgUrl.introImg
        this.recuritImg = imgUrl.recruitImg
        this.trainImg = imgUrl.trainImg
        this.developImg = imgUrl.developImg
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll',this.scrolling)
  }
}
</script>

<style lang="less" scoped>
@import './style.less';
</style>